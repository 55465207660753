import { createUseI18n } from 'keycloakify/login';

export const { useI18n } = createUseI18n({
  en: {
    doLogIn: 'Log in',
    logIn: 'Log in to your account',
    connectWith: 'Connect to Neon with:',
    registerTitle: 'Create your free account',
    doRegister: 'Continue',
    continueWithEmail: 'or continue with email',
    loginWithEmail: 'or login in with your email',
    emailVerifyTitle: 'Almost there',
    firstName: 'First Name',
    lastName: 'Last Name',
    passwordConfirm: 'Password confirmation',
    emailForgotTitle: 'Reset your password',
    emailInstruction:
      'Enter your email address and we will send you a password reset link.',
    emailVerifyInstruction1:
      'We sent you an email with a link to activate your Neon account.',
    emailVerifyInstruction2: "Didn't get the email?",
    emailVerifyInstruction3: 'Resend activation link',
    proceedWithAction: 'Click here to proceed',
    backToApplication: 'Back to login',
    invalidUserMessage: 'Invalid email or password.',
    // After updating Keycloak from 22 to 25,
    // it started showing both emailExistsMessage and usernameExistsMessage
    // when a user provided an email address during registration that was
    // already used. As as workaround, we can make this message empty.
    // Possibly related to https://github.com/keycloak/keycloak/issues/30933
    usernameExistsMessage: '',

    // Tab titles
    register_tab_title: 'Sign Up',
    login_tab_title: 'Log In',
    email_activation_tab_title: 'Email Verification',
    password_update_tab_title: 'Password Update',
    reset_password_tab_title: 'Password Reset',
    profile_update_tab_title: 'Profile Update',
    email_update_tab_title: 'Email Update',
    error_tab_title: 'Error',
  },
});

export type I18n = NonNullable<ReturnType<typeof useI18n>>;

export type MessageKey = Parameters<I18n['msg']>[0];
